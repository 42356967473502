<template>
    <div class="changePassword">
        <div class="title">个人中心</div>
        <el-card class="box-card userInfo">
            <div slot="header" class="clearfix">
                <span class="userInfo_title">用户信息</span>
                <i class="el-icon-paperclip" style="float: right; padding: 3px 0;font-size: 18px;font-weight: 700;"></i>
            </div>
            <div class="info">
                <div class="userName">
                    <span class="tit">用户名：</span><span>{{ userName }}</span>
                </div>
                <div class="mobileNumber">
                    <span class="tit">手机号：</span><span>{{ mobileNumber }}</span>
                </div>
            </div>
        </el-card>
        <el-card class="box-card changePassword-content">
            <div slot="header" class="clearfix">
                <span class="changePassword-title">修改绑定手机号</span>
                <i class="el-icon-paperclip" style="float: right; padding: 3px 0;font-size: 18px;font-weight: 700;"></i>
            </div>
            <el-form :model="userForm" :rules="rules" ref="userForm" label-width="120px" class="userForm">
                <el-form-item label="原手机号码" prop="oldMobileNumber">
                    <el-input
                        size="small"
                        v-model="userForm.oldMobileNumber"
                        placeholder="请输入原手机号码"
                        @blur="oldMobile"
                    ></el-input>
                </el-form-item>
                <el-form-item label="新手机号码" prop="newMobileNumber">
                    <el-input size="small" v-model="userForm.newMobileNumber" placeholder="请输入新手机号码"></el-input>
                </el-form-item>
                <el-form-item label="确认手机号码" class="verificationCode" prop="confirmMobileNumber">
                    <el-input
                        size="small"
                        v-model="userForm.confirmMobileNumber"
                        placeholder="请输入确认手机号码"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain size="small" @click="ModifySubmit()"> 保存 </el-button>
                </el-form-item>
            </el-form>
        </el-card>
        
       
    </div>
</template>
<script>
import userApi from "../../api/userApi";
export default {
    name: "changePassword",
    data() {
        return {
            userForm: {
                oldMobileNumber: "",
                newMobileNumber: "",
                confirmMobileNumber: ""
            },
            userName: "",
            mobileNumber: "",
            rules: {
                oldMobileNumber: [
                    {
                        required: true,
                        message: "请输入原手机号码",
                        trigger: "blur"
                    },
                    { validator: this.validateMobile, trigger: "blur" }
                ],
                newMobileNumber: [
                    {
                        required: true,
                        message: "请输入新手机号码",
                        trigger: "blur"
                    },
                    { validator: this.validateMobile, trigger: "blur" }
                ],
                confirmMobileNumber: [
                    {
                        required: true,
                        message: "确认手机号码不能为空",
                        trigger: "blur"
                    },
                    // { validator: regconfirmMobileNumber, trigger: "blur" }
                    { validator: this.regconfirmMobileNumber, trigger: "blur" }
                ]
            },

            isOldMobileNumber: false
        };
    },
    mounted() {
        this.getUserInfo();
    },
    methods: {
        getUserInfo() {
            userApi.getUserInfo().then(res => {
                if (res.status == 200) {
                    this.userName = res.data.userName;
                    this.mobileNumber = res.data.mobileNumber;
                }
            });
        },
        ModifySubmit() {
            if (this.isOldMobileNumber) {
                this.$refs.userForm.validate(valid => {
                    if (valid) {
                        userApi
                            .updateMobileNumber({
                                oldMobileNumber: this.userForm.oldMobileNumber,
                                mobileNumber: this.userForm.newMobileNumber
                            })
                            .then(response => {
                                if (response.code == 0) {
                                    this.$message.success(response.message);
                                    this.userForm = {
                                        oldMobileNumber: "",
                                        newMobileNumber: "",
                                        confirmMobileNumber: ""
                                    };
                                }else {
                                    this.$message.error(response.message);
                                }
                            });
                    } else {
                        return false;
                    }
                });
            } else {
                this.$message.error("原手机号码错误");
                return false;
            }
        },
        oldMobile() {
            userApi
                .verifyOldMobileNumber({
                    oldMobileNumber: this.userForm.oldMobileNumber
                })
                .then(res => {
                    if (!res.data) {
                        this.isOldMobileNumber = false;
                        this.$message.error("原手机号码错误");
                        return false
                    } else {
                        this.isOldMobileNumber = true;
                    }
                });
        },
        validateMobile(rule, value, callback) {
            const reg = /^1[3456789]\d{9}$/;
            if (value === "") {
                callback(new Error("未输入手机号码"));
            } else if (!reg.test(value)) {
                callback(new Error("未输入正确的手机号码"));
            } else {
                callback();
            }
        },
        regconfirmMobileNumber(rule, value, callback) {
            const reg = /^1[3456789]\d{9}$/;
            if (value === "") {
                callback(new Error("未输入手机号码"));
            } else if (!reg.test(value)) {
                callback(new Error("未输入正确的手机号码"));
            } else if (value !== this.userForm.newMobileNumber) {
                callback(new Error("两次输入手机号码不一致!"));
            } else {
                callback();
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.changePassword {
    background: #fff;
    min-height: 85vh;
    padding: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    border-radius: 4px;
    .title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 30px;
    }
    .userInfo {
        margin: 50px;
        .userInfo_title {
            font-size: 16px;
            color: #222222;
            margin-bottom: 40px;
            font-weight: 700;
        }
        .info {
            margin-left: 40px;
            .userName {
                font-size: 14px;
                color: #606266;
                margin-bottom: 30px;
            }
            .mobileNumber {
                font-size: 14px;
                color: #606266;
            }
            .tit {
                font-weight: 600;
            }
        }
    }
    .changePassword-content {
        margin-top: 20px;
        background: #ffffff;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        box-sizing: border-box;
        margin-left: 50px;
        .changePassword-title {
            font-size: 16px;
            color: #222222;
            margin-bottom: 40px;
            font-weight: 700;
        }
        .userForm {
            width: 416px;
        }

        /deep/.el-form-item__label {
            font-weight: 600;
        }
    }
}

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

  .box-card {
    width: 600px;
    /deep/.el-card__header {
            background: #f4f4f5;
        }
  }
</style>
